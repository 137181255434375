<template>
  <div class="allComment">
    <div class="border-bottom">
      <div class="subjectHeader row">
        <div class="subjectTitle">
          <h4>{{ coursename }}</h4>
        </div>
        <div class="addBtn">
          <el-button round>我发起的</el-button>
          <el-button round>@ 关于我的</el-button>
          <el-button v-if="!addshow" type="success" circle icon="el-icon-plus" @click="addshow = !addshow"
                     style="margin-top: 30px;margin-right: 10px"></el-button>
          <el-button v-if="addshow" type="danger" circle icon="el-icon-minus" @click="addshow = !addshow"
                     style="margin-top: 30px;margin-right: 10px"></el-button>
        </div>
      </div>
      <transition name="el-zoom-in-top">
        <AddDiscussion v-show="addshow" class="transition-box"></AddDiscussion>
      </transition>
    </div>
    <div class="container">
      <div class="discussionArea" v-for="discussion in allDiscussions" :key="discussion.id">
        <CommentBox :comment-title="discussion.title" :comment-content="discussion.description"
                    :gmt-create="discussion.gmtCreate" :discussion-id="discussion.id"
                    :reply="discussion.comments" @freshDiscussion="getAllDiscussion"></CommentBox>
      </div>
      <div class="block" style="text-align: center;padding-bottom: 100px">
        <span class="demonstration">调整每页显示条数</span>
        <el-pagination
          @size-change="handlePageSizeChange"
          @current-change="getAllDiscussion"
          :current-page.sync="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <div class="backgroundPic">
      <img
        src="https://cos.mooc.huangzhaokun.top/moocImg/undraw_Public_discussion_re_w9up.png"
        alt="">
    </div>
  </div>
</template>

<script>
import CommentBox from '@/components/CourseMain/CommentBox'
import AddDiscussion from '@/components/AddDiscussion'
import axios from 'axios'

export default {
  name: 'AllComment',
  components: {
    AddDiscussion,
    CommentBox
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 6,
      pageSizes: [6, 12, 20],
      total: 0,
      coursename: '',
      addshow: false,
      allDiscussions: []
    }
  },
  created () {
    this.getAllDiscussion()
  },
  methods: {
    handlePageSizeChange (val) {
      this.pageSize = val
      this.getAllDiscussion()
    },
    getAllDiscussion () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/discussion/showDiscussionsByTermId',
        params: {
          termId: this.$route.params.termId,
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      }).then(res => {
        if (res.data.status) {
          this.allDiscussions = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.backgroundPic {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.backgroundPic img {
  width: 400px;
  height: auto;
}

.allComment {
  padding: 20px 0;
}

.subjectTitle {
  line-height: 1.2;
}

.subjectHeader {
  font-size: 24px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.addBtn {
}
</style>
