<template>
  <div>
    <el-card v-if="isComponentAlive" class="commentBox" shadow="hover">
      <div class="cBoxHeader">
        <div class="commentTitle">
          <h4>{{ commentTitle }}
          </h4>
        </div>
      </div>
      <el-divider content-position="right">{{ gmtCreate }}</el-divider>
      <div class="cBoxMain">
        <div class="commentMain">
        <pre>
          {{
            commentContent
          }}</pre>
        </div>
      </div>
      <div class="cBoxFooter" style="
      margin:0 20px">
        <AddComment v-show="addCommentV" @doReload="reload" :discussion-id="discussionId"></AddComment>
        <el-button type="warning" icon="el-icon-edit" round @click="addCommentV=!addCommentV">立即回复</el-button>
        <el-button type="primary" round @click="goDetail" icon="el-icon-chat-line-round">讨论详情
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import AddComment from '@/components/AddComment'
// import CommentBoxSp from '@/components/CourseMain/CommentBoxSp'

export default {
  name: 'CommentBox',
  components: {
    AddComment
    // CommentBoxSp
  },
  data () {
    return {
      isComponentAlive: true,
      drawer: false,
      direction: 'rtl',
      addCommentV: false,
      allComment: []
    }
  },
  props: {
    discussionId: Number,
    username: String,
    commentTitle: String,
    commentContent: String,
    gmtCreate: String,
    replyCount: Number,
    reply: []
  },
  methods: {
    goDetail () {
      this.$router.push({
        name: 'DiscussionDetails',
        params: { id: this.discussionId }
      })
    },
    reload () {
      this.isComponentAlive = false
      this.$nextTick(function () {
        this.isComponentAlive = true
      })
      this.addCommentV = false
      this.$emit('freshDiscussion')
    }
  }
}
</script>

<style scoped>
.commentBox {
  padding: 10px 20px;
  line-height: 1;
  width: 90%;
  margin: 15px auto;
}

.cBoxHeader span {
  vertical-align: middle;
  font-size: 20px;
  margin: 0 8px;
}

.cBoxHeader p {
  margin: 3px 8px;
}

.cBoxMain {
  padding: 0 60px;
}

.commentMain {
  padding: 0 30px;
  text-align: justify;
  text-justify: inter-ideograph;
}

.commentMain pre {
  line-height: 1.5;
  font-family: "Poppins", Arial, sans-serif;
}

.cBoxFooter {
  text-align: center;
  margin: 0 auto;
}
</style>
