<template>
  <div class="addDiscussion">
    <div class="contact-left">
      <!-- Contact Form -->
      <el-form id="contact-form" v-model="discussionForm">
        <input type="text" v-model="discussionForm.title" name="name" id="name" required placeholder="Discussion Name*">
        <div class="send-btn-div">
          <textarea id="massage" v-model="discussionForm.description" name="massage" placeholder="Content"></textarea>
          <button class="send-btn" @click="addDiscussion"><i class="el-icon-s-promotion"></i></button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'addDiscussion',
  data () {
    return {
      discussionForm: {
        termId: '400001',
        title: '',
        description: ''
      }
    }
  },
  methods: {
    addDiscussion () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/discussion/makeDiscussion',
        params: {
          termId: this.discussionForm.termId,
          title: this.discussionForm.title,
          description: this.discussionForm.description
        }
      }).then(res => {
        console.log('添加res:', res)
      })
    }
  }
}
</script>

<style scoped>
.addDiscussion {
  margin: 20px auto;
}

#contact-form input {
  display: block;
  min-width: 100%;
  margin-bottom: 10px;
  height: 55px;
  padding: 7px 10px;
  background: #fff;
  border: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  font-size: 16px;
}

#contact-form textarea {
  min-width: 100%;
  height: 160px;
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border: none;
  line-height: initial;
  box-sizing: border-box;
  font-size: 16px;
}

.send-btn-div {
  position: relative;
  line-height: 0;
}

.send-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 80px;
  background: #333;
  border: none;
  font-size: 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  color: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: inset 0 0 15px 5px #333;
}

.send-btn:hover {
  background: transparent;
  color: #333;
  box-shadow: inset 0 0 0 5px #333;
}

#contact-form input:focus,
#contact-form textarea:focus {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1), inset 0 -3px #333;
  outline: none;
}

.contact-left {
  position: relative;
}
</style>
